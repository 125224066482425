@import '../../../styles/globals.css';
@import 'react-loading-skeleton/dist/skeleton.css';
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Aeonik-Regular', 'Helvetica', 'Arial', sans-serif;
  color: #cdcdcd;
  background-color: #0d0d0d;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: square;
}

li {
  color: inherit;
}

/* Turn off spinners on number input fields -- webkit unique property*/
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.bg-holy-radial-6 {
  background: radial-gradient(
      63.94% 63.94% at 50% 0%,
      rgba(255, 255, 255, 0.06) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #090909;
}

.bg-holy-radial-pending-redemption {
  border-radius: 6px;
  background: radial-gradient(
      63.94% 63.94% at 50% 0%,
      rgba(255, 255, 255, 0.06) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #1c1f1e;
}

@layer utilities {
  .cf-gray-gradient {
    background: linear-gradient(91deg, #d2d2d2 12.83%, #ffffff 50.15%, #9f9f9f 94.25%);

    @apply bg-clip-text text-transparent;
  }
}
